/* Meet the team CSS */
.meet-the-team-list,
.events-list {
  margin-top: 20px;
  li {
    img {
      border-radius: 50px;
    }
    h3 {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 1rem;
      font-style: italic;
    }
    .short-bio.hidden {
      display: none;
    }
    .full-bio.hidden {
      display: none;
    }
  }
}

.meet-the-committee-list {
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
  li {
    img {
      border-radius: 100px;
      -webkit-filter: grayscale(100%) brightness(120%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%) brightness(120%);
    }
    display: inline-block;
    vertical-align: top;
    margin: 20px;
    h3 {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

.events-list {
  li {
    margin-bottom: 1rem;
    border-bottom: 1px solid #8080802e;
    &:last-child {
      border-bottom: none;
    }
  }
}

.global-banner {
  margin-bottom: 2rem;
}

.aquire-amount {
  .aquire-amount-input-group {
    .quick-pay-buttons,
    .other-amount-input {
      &.hidden {
        display: none;
      }
      .other-amount-input-button {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        &:last-child {
          margin: 0;
        }
      }
      .input-title {
        display: block;
        font-size: 18px;
        font-weight: bold;
        font-style: italic;
      }
      .input-prefix {
        font-size: 20px;
      }
    }
  }
}

.oneoff-donation-capture-amounts,
.oneoff-capture-person,
.oneoff-payment,
.payment-complete,
.payment-error {
  &.hidden {
    display: none;
  }
}
